import React, { Component } from 'react';
import Layout from '../components/layout'
import Footer from '../components/footer'
import SEO from '../components/seo'
import Styled from 'styled-components'


import { Row, Col } from 'antd'

const CGV = Styled.div`
    p {
        font-size: 16px;
    }
`;

class Legal extends Component {
    constructor(props){
        super(props);
        this.state = {
            navbar: 'top',
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if(window.scrollY > 100){
            this.setState({navbar: 'no-top'})
        } else {
            this.setState({navbar: 'top'})
        }
    };

    render() {
        return (
            <Layout onScroll={this.handleScroll} position={this.state.navbar} currentPage='legal'>
                <SEO title="Shalee, l'agent digital équestre" keywords={[`Shalee`,`Application`,`Mobile`,`Cheval`,`Chevaux`,`Club`,`Equestre`,`Equitation`,`Cavalier`]}/>
                <div style={{paddingTop: '100px'}}>
                    <div style={{textAlign:"center", margin:"50px", marginBottom:"100px"}}>
                        <h1 className="title" style={{textAlign:"center"}}>CONDITIONS GÉNÉRALES</h1>
                        <h1 style={{textAlign:"center"}}>Conditions Générales de Ventes</h1>
                    </div>
                    <Row className="container"  type="flex" justify="space-around" align="top" gutter={50}>
                        <Col xs={34} sm={34} md={16} lg={16} xl={16}>
                            <CGV>
<h3>A notre sujet</h3>
<p>shalee.io est un site web et application mobile (notre « service ») exploités par Tim0 (« nous » ou « Shalee »), société sous la forme EURL, au capital de 1 euros dont le siège social est situé 3 place des tamaris, 37420, beaumont-en-véron, et immatriculée au RCS en France sous le n° 817 939 697
Vous pouvez nous contacter :
Mail : florian.guerin@tim0.com</p>

<h3>Objectif</h3>
<p>Notre service à pour but de fournir une nouvelle expérience simple et pratique en vous mettant en relation avec nos clubs équestres partenaires. Shalee agit en tant qu’agent pour le compte des clubs partenaires afin de proposer de nouveaux services au sein de chaque établissement</p>

<h3>Disponibilité de nos services</h3><p>
Le service Shalee est disponible pour tous les clubs partenaires. Le système de géolocalisation nous permet de vous indiquer la distance du club partenaire le plus proche de vous. Si un club n’est pas partenaire, nous ne pourrons en aucun cas proposer le service Shalee et vous ne pourrez trouver en aucun des informations sur un club non partenaire.
</p>
<h3>Inscription</h3><p>
Chaque inscription à un événement passée par le biais de notre service sera transmise au club partenaire. L’inscription est définitive une fois que vous validé le paiement pour l’inscription à un événement d’un Club. Vous pouvez retrouver vos factures liées aux inscriptions dans la rubrique factures de notre service.
Le contrat d’inscription que vous effectuez par notre intermédiaire est conclu entre vous et le club, et n’est conclu qu’une fois le paiement effectué.
A travers le processus d’inscription, vous acceptez expressément l’application des présentes conditions générales de prestations de service.
</p>
<h3>Accès à nos services</h3><p>
L’accès à notre Site et à notre Service est autorisé de manière temporaire et nous nous réservons le droit de supprimer ou de modifier sans préavis l’accès à notre Site ou à notre Service. Nous ne sommes pas responsables si, pour quelque motif que ce soit, notre Site ou notre Service n’est pas disponible à un moment donné ou pendant une période donnée. Le cas échéant, nous pouvons limiter l’accès à certaines parties de notre Site ou de notre Service ou l’accès à l’ensemble de notre Site ou Service aux utilisateurs inscrits. Vous êtes responsable de la préservation de la confidentialité de vos informations de connexion et des activités de votre compte. Si vous avez des inquiétudes au sujet de vos informations de connexion ou si vous pensez qu’elles ont été piratées, prenez immédiatement contact avec florian.guerin@tim0.com pour nous en informer. Nous pouvons désactiver votre compte à tout moment.

Nous souhaitons mettre régulièrement à jour notre Site et notre Service et pouvons en modifier le contenu à tout moment. En cas de nécessité, nous pouvons suspendre l’accès à notre Site et à notre Service ou les fermer pour une durée indéterminée. Les éléments de notre Site ou notre Service peuvent être dépassés à un moment donné et nous ne sommes en aucun cas tenus d’actualiser ces éléments.

 
</p>
<h3>Utilisation de notre service</h3><p>
Vous ne pouvez utiliser notre Service qu’à des fins légales. Vous ne pouvez utiliser notre Site ou notre Service d’aucune manière qui contrevienne à une loi ou réglementation locale, nationale ou internationale applicable ni pour envoyer, recevoir sciemment, télécharger, utiliser ou réutiliser des éléments qui ne sont pas conformes à nos normes en matière de contenu. Vous vous engagez également à ne pas accéder sans autorisation à, endommager ou perturber une partie de notre Site ou de notre Service ou un réseau ou un matériel utilisé dans la fourniture de notre Service.

 
</p>
<h3>Prix et paiement</h3><p>
Le prix est indiqué dans notre service. Les prix sont TTC et incluent la TVA. Les prix varient selon les clubs. Les prix sont susceptibles d’être révisés à tout moment mais les modifications ne s’appliquent pas aux réservations pour lesquelles vous avez reçu la confirmation dans la section « Facture » de votre compte, sauf s’il y a une erreur manifeste sur le prix : dans ce cas, nous vous avertissons dès que possible du problème de prix. Malgré tous nos efforts, certains prix dans notre service peuvent être erroné. Le Club partenaire concerné vérifie normalement ses prix régulièrement.
Toutes les réservations peuvent être réglées par carte bancaire par le biais de notre Service.
Sont acceptés comme moyens de paiement : les cartes de crédit, carte bancaire, (visa, mastercard etc.).
Pour les paiements par carte bancaire, nous utilisons la plateforme <a href="https://stripe.com/fr">Stripe</a>. Vos informations bancaires sont traités directement par Stripe et Stripe seulement.

En acceptant de contracter sur la base des présentes conditions, vous reconnaissez explicitement votre obligation de paiement. Une fois que votre transactions a été confirmée, votre carte de crédit reçoit une autorisation de débit et le montant est réservé pour être débité.
Le règlement est effectué directement à Tim0 qui le transmet ensuite au Club Partenaire. Nous sommes autorisés par nos Clubs partenaires à accepter le règlement pour leur compte et le règlement du prix des réservations à notre bénéfice vous dégage de vos obligations de payer ce prix au Club partenaire.

 
</p>
<h3>Notre responsabilité</h3><p>
Dans les limites autorisées par la loi, Shalee fournit son Service et son contenu « en l’état » et « en fonction de la disponibilité ».
Selon les dispositions ci-dessous, ni Shalee ni quelque Club partenaire que ce soit n’ont de responsabilité vis-à-vis de vous en ce qui concerne des pertes directes, indirectes, particulières ou des dommages d’ordre contractuel, dus à un préjudice (dont la négligence) ou par ailleurs découlant de votre utilisation ou de votre incapacité à utiliser notre Service. Si la responsabilité de Shalee ou du Club partenaire est engagée vis-à-vis de vous, notre responsabilité totale cumulée est limitée au prix d’achat de la réservation que vous avez payés dans votre commande. Cela ne comprend pas et ne limite en aucune manière la responsabilité de Shalee ou d’un club partenaire sur un sujet pour lequel il serait illégal que nous excluions ou tentions d’exclure ou qu’il exclue ou tente d’exclure notre ou sa responsabilité, en particulier, la responsabilité dans un décès ou des blessures corporelles dû (dues) à la négligence ou à une fraude ou à une déclaration frauduleuse.
Le contrat de vente est conclu entre vous et le Club. Le Club partenaire que vous choisissez est un commerçant indépendant, distinct de notre société et sur lequel nous n’avons aucun contrôle. En conséquence, en dehors des strictes conditions légales, notre responsabilité ne saurait être engagée à ce titre.
Le Club partenaire, vendeur, est tenu des défauts de conformité du bien au contrat dans les conditions de l’article L 211-4 du Code de la consommation et des défauts de la chose vendue en cas de vices cachés (article 1641 Code civil) et de la garantie légale de conformité (article L211-4 et suivant Code de la consommation).

 
</p>
<h3>Annulation</h3><p>
Le droit d’annulation est déterminé par le club partenaire directement. Si jamais il est impossible d’annuler via l’application dû au paramètres du club partenaire, le client peut annuler la réservation en contactant le club directement. Shalee et le Club Partenaire peuvent annuler une inscription à un événement et vous en informent dans ce cas. Les inscriptions annulées conformément à cette clause ne vous sont pas facturées. Tout règlement effectué avant l’annulation d’une inscription par Shalee ou un Club Partenaire est généralement remboursé par le même moyen que celui que vous avez utilisé pour payer votre commande. Chaque inscription annulée de votre part après confirmation d’inscription de votre part, celle-ci vous est facturée.

 
</p>
<h3>Utilisation de vos données</h3><p>
Nous collectons certaines données à votre sujet dans le cadre de votre utilisation de notre Service. Ce point est décrit de manière plus détaillée dans notre politique de respect de la vie privée.
Notre numéro de déclaration à la CNIL est en cours d’attribution.
Concernant les cookies, un bandeau informe les internautes de leur finalité, qui pourront les refuser ou les accepter. La durée maximum de ce consentement étant de 13 mois.

Nous collectons vos informations personnelles lorsque vous interagissez avec nous ou avec nos services, comme lorsque vous utilisez nos Sites pour passer une commande. Nous observons aussi la façon dont les visiteurs utilisent nos Sites, afin de nous aider à améliorer nos services et à optimiser notre expérience client.

Nous collectons des informations:
<ul>
<li>lorsque vous créez un compte ou que vous changez les paramètres de ce compte;
</li>
<li>lorsque vous faites une réservation ou pendant le traitement de celle-ci (paiement);
</li>
<li>lorsque vous nous contactez directement par courriel, téléphone, courrier postal, messagerie ou via notre chat
</li>
<li>lorsque vous naviguez et utilisez nos Sites (avant et après la création d’un compte).
</li>
</ul>

Nous collectons aussi des informations auprès de sites tiers, tels que notre prestataire de services de détection de la fraude.
</p>
<h3>Divulgation de vos données</h3><p>
Les informations que nous recueillons à votre sujet seront transférées et stockées sur nos serveurs situés dans l’UE. Nous sommes très prudents et transparents en ce qui concerne ceux avec qui vos informations sont partagées.
<ol>
    <li>Partage de vos informations en interne</li>
        <ul>
            <li>Nous ne partageons vos informations avec d’autres sociétés du groupe Shalee que lorsque cela est nécessaire aux fins énoncées au paragraphe 2.</li>
        </ul>
    <li>Partage de vos informations avec des tiers:Nous partageons vos informations avec des prestataires de services tiers. Les types de prestataires de services tiers avec lesquels nous partageons vos informations comprennent:</li>
        <ol>
            <li>prestataires de services de paiement (prestataires de services de paiement en ligne et prestataires de services de détection de fraude): afin qu’ils puissent nous fournir leurs services, par exemple lorsqu’ils traitent pour nous des informations telles que les paiements par carte de crédit, lorsqu’ils vous fournissent des services d’assistance ou qu’ils procèdent pour notre compte à des contrôles anti-fraude;</li>
            <li>prestataires de services informatiques (prestataires de services cloud): à des fins de stockage et d’analyse des données, et</li>
            <li>clubs partenaires: dès lors que vous effectué une réservation, afin qu’ils puissent les satisfaire, qu’ils puissent régler d’éventuels problèmes ou encore améliorer la qualité de leurs services;</li>
            <li>partenaires marketing et publicitaires: ils feront en sorte que vous visualisiez des annonces plus adaptées à vos besoins et vous enverront des courriels marketing en notre nom.</li>
        </ol>
        Shalee prendra toutes les mesures raisonnablement nécessaires pour que vos données soient traitées en toute sécurité et conformément à la présente politique de confidentialité lorsqu’elles sont communiquées à des tiers.

    <li>Si notre entreprise conclut un accord de « joint-venture » avec une autre entité commerciale, achète une autre entité commerciale ou est vendue ou fusionnée avec elle, vos informations peuvent être divulguées ou transférées à cette société cible, à nos nouveaux partenaires commerciaux ou propriétaires ou à leurs conseillers.
</li>
    <li>Nous pouvons également partager vos informations:
</li>
            <ol>
            <li>si nous sommes dans l’obligation de divulguer ou de partager vos informations afin de nous conformer (et/ou si nous pensons que tel est le cas) à ces obligations ou exigences réglementaires. Ceci inclut l’échange d’informations avec d’autres sociétés et organisations à des fins de protection contre la fraude et de détection de celle-ci;
</li>
            <li>afin de faire respecter nos conditions contractuelles avec vous ainsi que toute autre politique ou tout autre accord;
</li>
            <li>afin de protéger les droits de Shalee, des clubs partenaires ou d’autres parties, et de prévenir la fraude, et
</li>
            <li>avec les tiers dont nous pensons que la participation est nécessaire afin de détecter ou de prévenir la criminalité, par exemple avec la police.
</li>
            </ol>
</ol>

</p><h3>Evenements Independants de notre volonte</h3><p>
Aucune partie n’est responsable vis-à-vis de l’autre d’un retard ou d’une absence d’exécution de ses obligations aux termes du présent contrat si ledit retard ou ladite absence est indépendant(e) de sa volonté dont, de manière non exhaustive, les événements suivants : catastrophe naturelle, disposition gouvernementale, guerre, incendie, inondation, explosion ou mouvements populaires. Pour éviter tout doute, rien dans la clause 11 n’autorise le client à ne pas s’acquitter de ses obligations de règlement aux termes du présent contrat.

 
</p>
<h3>Renonciation</h3><p>
Ni vous, ni Shalee, ni le Club partenaire n’êtes responsables vis-à-vis des autres parties d’un retard ou d’une absence d’exécution de ses obligations aux termes du présent contrat si ledit retard ou ladite absence est indépendant(e) de sa volonté dont, de manière non exhaustive, les événements suivants : catastrophe naturelle, disposition gouvernementale, guerre, incendie, inondation, explosion ou mouvements populaires.

 
</p>
<h3>Disjonction</h3><p>
Si une disposition du présent contrat est jugée illégale ou inapplicable, la force exécutoire intégrale des autres dispositions demeure.

 
</p>
<h3>Contrat indivisible</h3><p>
Les présentes conditions contiennent l’intégralité du contrat entre les parties relatif à son objet et remplacent l’ensemble des contrats et accords antérieurs entre les parties relatifs à cet objet.
</p>
<h3>Droit Et Competence</h3><p>
Les tribunaux français ont compétence pour toutes les plaintes découlant de ou liées à une utilisation de nos Services. Les présentes conditions d’utilisation et tout conflit ou plainte découlant de ou lié(e) à ces conditions d’utilisation ou leur objet ou formation (dont les conflits ou plaintes non contractuel(le)s) doivent être régis par et interprétés conformément au droit français. Conformément aux dispositions du Code de la consommation, il vous est possible de recourir à un mode alternatif de règlement des litiges ou à une procédure de médiation conventionnelle. La recherche d’une telle solution amiable interrompt les délais pour agir. A défaut, la juridiction compétente sera déterminée par les règles de droit commun.
</p>
<h3>Modification</h3><p>
Shalee peut réviser les présentes conditions à tout moment en modifiant cette page. Vous êtes censé vérifier cette page de temps à autre pour prendre connaissance des modifications que nous avons apportées car elles sont contraignantes pour vous.
</p>
<h1 style={{textAlign:"center"}}>Conditions Générales d’Utilisation</h1>
<h3>Utilisation Acceptable</h3><p>
Vous ne pouvez utiliser notre Service qu’à des fins légales. Vous ne pouvez utiliser notre Site ou notre Service d’aucune manière qui contrevienne à une loi ou réglementation locale, nationale ou internationale applicable ni pour envoyer, recevoir sciemment, télécharger, utiliser ou réutiliser des éléments qui ne sont pas conformes à nos normes en matière de contenu figurant dans la clause « Normes En Matiere De Contenu » ci-dessous. Vous vous engagez également à ne pas accéder sans autorisation à, endommager ou perturber une partie de notre Site ou de notre Service ou un réseau ou un matériel utilisé dans la fourniture de notre Service.
</p>
<h3>Normes En Matiere De Contenu</h3><p>
Ces normes en matière de contenu s’appliquent à tous les éléments par lesquels vous contribuez à notre Service (les « Contributions ») et aux éventuels Services interactifs qui y sont associés. Vous devez respecter l’esprit et la lettre des normes suivantes. Les normes s’appliquent à chaque partie des Contributions et à leur globalité. Les Contributions doivent être précises (lorsqu’elles exposent des faits), être sincères (lorsqu’elles exposent des convictions) et respecter la législation applicable en France et dans le pays d’où elles sont émises. Les Contributions ne doivent pas :
<ul>
    <li>contenir des éléments diffamatoires pour une personne, obscènes, injurieux, haineux ou incendiaires, promouvoir des scènes explicites sur le plan sexuel ou promouvoir la violence ou promouvoir la discrimination sur la base de la race, du sexe, de la religion, de la nationalité, du handicap, de l’orientation sexuelle ou de l’âge ;</li>
    <li>enfreindre des droits d’auteur, droits sur des bases de données ou marques de commerce d’un tiers ;
</li>
    <li>être susceptibles de tromper un tiers ou être en infraction avec une obligation légale vis-à-vis d’un tiers, comme une obligation contractuelle ou une obligation de discrétion ou promouvoir une activité illégale ;
</li>
    <li>être menaçantes, porter atteinte à ou violer l’intimité d’un tiers, provoquer une gêne, un dérangement ou une anxiété inutile ou être susceptibles de harceler, perturber, embarrasser, alarmer ou gêner un tiers ;
</li>
    <li>être utilisées pour se faire passer pour quelqu’un d’autre ou usurper votre identité ou tromper sur votre affiliation avec une personne ou donner l’impression qu’elles émanent de nous, si tel n’est pas le cas ou
</li>
    <li>préconiser, promouvoir ou aider à un acte illégal comme (à titre d’exemple uniquement) une violation de droit d’auteur ou un piratage d’ordinateur.
</li>
</ul>
</p>
<h3>Suspension Et Resiliation</h3><p>
Le non-respect de la section Utilisation acceptable et/ou Normes en matière de contenu des présentes conditions d’utilisation constitue une violation déterminante des conditions d’utilisation et peut nous amener à prendre tout ou partie des mesures suivantes :
<ul>
    <li> suppression immédiate, temporaire ou permanente de votre droit d’utiliser notre Service ;</li>
    <li>suppression immédiate, temporaire ou permanente des contributions ou éléments téléchargés par vous sur notre Service ;</li>
    <li>envoi d’un avertissement à votre encontre ;</li>
    <li>action en justice contre vous, comprenant une procédure en vue du remboursement de tous les coûts (dont, de manière non exhaustive, les coûts administratifs et les frais de justice raisonnables) entraînés par l’infraction ;</li>
    <li>communication de ces informations aux autorités chargées de l’application des lois si nous l’estimons légitimement nécessaire.</li>
</ul>

Les réactions décrites dans cette clause ne sont pas exhaustives et nous pouvons prendre toutes les autres mesures que nous jugeons légitimement adaptées. Le nom du responsable de la publication et les motifs de retrait apparaitront sur le Site.
</p>
<h3>Droits De Propriete Intellectuelle</h3><p>
Nous sommes le propriétaire ou le détenteur de licence de tous les droits de propriété intellectuelle de notre Site et de notre Service et des éléments publiés sur ce Site (à l’exception de vos contributions). Ces travaux sont protégés par les lois et traités sur le droit d’auteur dans le monde entier. Tous ces droits sont réservés. Vous n’avez pas le droit de copier, reproduire, republier, télécharger, envoyer, diffuser, transmettre, communiquer au public ou utiliser un contenu de notre Site de quelque manière que ce soit, sauf pour votre usage personnel sans caractère commercial.
</p>
                            </CGV>
                        </Col>
                    </Row>
                    <Footer/>
                </div>
            </Layout>
        );
    }
}

export default Legal;